import { memo, lazy, useMemo } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

const SignIn = lazy(() => import("pages/SignIn"));
const SignUp = lazy(() => import("pages/SignUp"));
const Home = lazy(() => import("pages/Home"));
const SubscriptionMovies = lazy(() => import("pages/SubscriptionMovies"));
const ManageSubscription = lazy(() => import("pages/ManageSubscription"));
const Video = lazy(() => import("pages/Video"));
const AvailableVideos = lazy(() => import("pages/AvailableVideos"));

const RequireAuth = lazy(() => import("./RequireAuth"));
const RequireNotAuth = lazy(() => import("./RequireNotAuth"));
const AuthLayout = lazy(() => import("./AuthLayout"));
const DashboardLayout = lazy(() => import("./DashboardLayout"));

function Root() {
  let location = useLocation();

  const redirectState = useMemo(() => {
    return { from: location };
  }, [location]);

  return (
    <Routes>
      <Route
        path="/"
        element={<Navigate to="/auth/sign-in" state={redirectState} replace />}
      />
      <Route
        path="/auth/sign-in"
        element={
          <RequireNotAuth>
            <AuthLayout />
          </RequireNotAuth>
        }
      >
        <Route index element={<SignIn />} />
      </Route>
      <Route
        path="/auth/sign-up/:invitationToken"
        element={
          <RequireNotAuth>
            <AuthLayout />
          </RequireNotAuth>
        }
      >
        <Route index element={<SignUp />} />
      </Route>
      <Route
        path="/"
        element={
          <RequireAuth>
            <DashboardLayout />
          </RequireAuth>
        }
      >
        <Route index element={<Home />} />
        <Route path="my" element={<AvailableVideos />} />
        <Route path="subscription" element={<SubscriptionMovies />} />
        <Route path="subscription/manage" element={<ManageSubscription />} />
        <Route path="video/:videoId" element={<Video />} />
      </Route>
    </Routes>
  );
}

export default memo(Root);
